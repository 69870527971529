import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/TeamMemberLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Michael is an award winning, internationally published producer, director and post production expert whose work speaks for itself on our CLHbid.com website. He has produced directly for hundreds of clients across North America throughout the years, while serving as owner and account manager of a media production company.`}</p>
    <p>{`His work has taken him all around the globe from Australia and New Zealand, through the lower 48 and beyond. His passion for extreme experiences using helicopters, drones, 4K and P.O.V. cameras in his work adds energy to almost any production and draws the viewer into every scene.`}</p>
    <p>{`Being an in-house member of the CLHBid.com team, Michael adds professionalism in every aspect of the production. From scriptwriting and storyboarding to filming, editing, design and final display of the picture, his skills allow the production of a seamless, professional look the whole way through. By logging hundreds of hours filming aerial landscapes, he creates the most breathtaking motion pictures to show off any property. He is also the team’s liaison with the CLHbid.com IT providers to ensure a reliable, high quality, user friendly website and online auction tool.`}</p>
    <p>{`Michael genuinely connects with farmers and ranchers.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      